import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Filters from '../components/filters'
import GuidesList from '../components/guidesList'
import SEO from '../components/seo'

import '../css/guides.scss'

class GuidesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guides: [],
      filters: {},
      selectedGuides: [],
    }
  }

  componentDidMount() {
    const { edges } = this.props.data.allMarkdownRemark
    let filters = {}
    const f = ['tags', 'topics', 'packages']
    _.forEach(f, e => (filters[e] = []))
    const guides = edges.filter(
      edge =>
        edge.node.frontmatter.collections &&
        edge.node.frontmatter.collections.indexOf('guides') !== -1
    )
    _.forEach(guides, guide => {
      let guideFilters = []
      const { frontmatter } = guide.node
      _.forEach(f, e => {
        filters[e].push(frontmatter[e])
        _.forEach(frontmatter[e], g => {
          guideFilters.push(e + '-' + _.camelCase(g))
        })
        _.merge(guide.node, { guideFilters })
      })
    })
    this.setState(state => ({
      guides,
      filters,
    }))
  }

  render() {
    const { guides, filters } = this.state
    return (
      <Layout>
        <div className="guides-list main flex">
          <SEO
            title="Home"
            keywords={[
              'DIF',
              'guides',
              'how-tos',
              'Digital Integrity Fellowship',
            ]}
          />
          <Filters filters={filters} />
          <GuidesList guides={guides} />
        </div>
      </Layout>
    )
  }
}

export default GuidesPage

export const pageQuery = graphql`
  query guidesQuery {
    allMarkdownRemark {
      edges {
        node {
          headings {
            value
            depth
          }
          id
          html
          frontmatter {
            title
            description
            created
            order
            tags
            collections
            topics
            packages
          }
        }
      }
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactModal from 'react-modal'
import { FaExpand, FaTimes } from 'react-icons/fa'
import { Subscribe } from 'unstated'

import FiltersContainer from '../state/FiltersContainer'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    border: '0',
    transition: 'all ease 0.7s',
    boxShadow: '0px 0px 10px 3px #0002',
  },
}

ReactModal.setAppElement('#___gatsby')
class Guide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false,
    }
    this.toggleDetails = this.toggleDetails.bind(this)
  }

  toggleDetails = e => {
    const s = this.state.showDetails
    this.setState(state => ({ showDetails: !s }))
  }

  render() {
    const { id, html, frontmatter } = this.props.guide
    const { title } = frontmatter

    return (
      <Subscribe to={[FiltersContainer]}>
        {f => (
          <div className={`guide guide-${id}`}>
            <div className="toggle-details" onClick={this.toggleDetails}>
              Read the guide <FaExpand />
            </div>
            <div className="guide-title">{title}</div>
            <div className="guide-summary">{frontmatter.description}</div>
            {_.map(['tags', 'topics', 'packages'], (elem, idx) => (
              <div key={idx} className={`guide-${elem} guide-meta`}>
                <span className="tags-title">{_.upperFirst(elem)}: </span>
                {_.map(frontmatter[elem], (e, i) => {
                  const t = elem + '-' + _.camelCase(e)
                  return (
                    <span
                      className={`tag ${
                        f.state.filters.indexOf(t) !== -1 ? 'selected' : ''
                      }`}
                      key={i}
                    >
                      {e}
                    </span>
                  )
                })}
              </div>
            ))}

            <ReactModal style={modalStyles} isOpen={this.state.showDetails}>
              <div className="flex flex-column">
                <div className="toggle-close" onClick={this.toggleDetails}>
                  close <FaTimes />
                </div>
                <div
                  className="w-100 w-70-l center"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </ReactModal>
          </div>
        )}
      </Subscribe>
    )
  }
}

Guide.propTypes = {
  guide: PropTypes.object,
}

export default Guide
